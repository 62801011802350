import React, { useContext, useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoArrowForwardSharp } from "react-icons/io5";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CustomStatusButton, TableHeader } from "../common";
import { dateFormatter, groupByDate } from "../../helper/formatDate";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { taskEndPoints } from "../../networking/Endpoints";
import { CustomAlertContext } from "../../context";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const TableHeads = ["Name", "Change Type", "Changed by", "Date", "Time"];

const keyName = (field) => {
  const fieldMap = {
    company: "Company",
    taskId: "Task ID",
    project: "Project",
    title: "Title",
    description: "Description",
    status: "Status",
    priority: "Priority",
    assign_to: "Assign To",
    estimated_time: "Estimated Time",
    hours_logged: "Hours Logged",
    completed_on: "Completed On",
    completed_by: "Completed By",
    assign_by: "Assign By",
    start_date: "Start Date",
    due_date: "Due Date",
    milestone: "Milestone",
    subtask: "Subtask",
  };
  return fieldMap[field] || "";
};

const LogDetails = ({ log }) => (
  <tr key={log?._id} className="w-full border-b hover:bg-slate-100">
    <td className="p-3">
      {log?.changes?.map((change, idx) => (
        <div key={idx} className="py-2">
          <span className="text-base font-semibold">
            {keyName(change?.field_name)}
          </span>
          <div className="flex items-center gap-2 text-sm">
            {renderChangeDetails(change)}
          </div>
        </div>
      ))}
    </td>
    <td className="text-sm">{log?.change_type || "--"}</td>
    <td className="p-3 text-sm">
      <UserBadge user={log?.change_by} />
    </td>
    <td className="p-3 text-sm">
      {dateFormatter(log?.createdAt, { format: "MMM DD, YYYY" }) || "--"}
    </td>
    <td className="p-3 text-sm">
      {dateFormatter(log?.createdAt, { format: "hh:mm A" }) || "--"}
    </td>
  </tr>
);

const renderChangeDetails = (change) => {
  switch (change?.field_name) {
    case "assign_to":
    case "completed_by":
      return (
        <>
          {change?.previous_value?.map((item) => (
            <UserBadge key={item?._id} item={item} BASE_URL={BASE_URL} />
          )) ?? "--"}
          <IoArrowForwardSharp />
          {change?.updated_value?.map((item) => (
            <UserBadge key={item?._id} item={item} BASE_URL={BASE_URL} />
          )) ?? "--"}
        </>
      );
    case "milestone":
      return (
        <>
          <span>{change?.previous_value?.milestone_name || "--"}</span>
          <IoArrowForwardSharp />
          <span>{change?.updated_value?.milestone_name || "--"}</span>
        </>
      );
    case "status":
      return (
        <>
          <CustomStatusButton selectedStatus={change?.previous_value} />
          <IoArrowForwardSharp />
          <CustomStatusButton selectedStatus={change?.updated_value} />
        </>
      );
    case "start_date":
    case "due_date":
    case "completed_on":
      return (
        <>
          <span>
            {dateFormatter(change?.previous_value, {
              format: "MMM DD, YYYY",
            }) || "--"}
          </span>
          <IoArrowForwardSharp />
          <span>
            {dateFormatter(change?.updated_value, { format: "MMM DD, YYYY" }) ||
              "--"}
          </span>
        </>
      );
    default:
      return (
        <>
          <span>{change?.previous_value || "--"}</span>
          <IoArrowForwardSharp />
          <span>{change?.updated_value || "--"}</span>
        </>
      );
  }
};

const UserBadge = ({ item, BASE_URL }) => (
  <div className="flex items-center gap-4">
    <img
      src={
        item?.avatar
          ? `${BASE_URL}/${item.avatar}`
          : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`
      }
      alt={item?.user_name[0]}
      className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
    />
    <span>{item?.user_name}</span>
  </div>
);

const TaskHistory = ({ taskId }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [openLogIndex, setOpenLogIndex] = useState(null);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchLogs = async () => {
      dispatch(setLoading(true));
      try {
        const response = await apiConnector(
          "GET",
          `${taskEndPoints.LOGS_API}/${taskId}`,
          null,
          { Authorization: `Bearer ${token}` }
        );
        setLogs(response?.data?.changeLogs ?? []);
      } catch (error) {
        setToastNotification(error?.message, "error");
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchLogs();
  }, [taskId, token, dispatch]);

  const groupedLogs = groupByDate(logs);

  return loading ? (
    <Skeleton count={5} width={"97%"} height={"22px"} className="mx-5 my-4" />
  ) : (
    <div className="w-full md:h-[82vh] p-4 overflow-auto">
      {Object?.keys(groupedLogs)?.length > 0 ? (
        Object?.entries(groupedLogs)?.map(([date, items], index) => (
          <div
            key={date}
            onClick={() =>
              setOpenLogIndex(openLogIndex === index ? null : index)
            }
            className="w-full p-2 border rounded-md cursor-pointer my-4"
          >
            <div className="flex justify-between items-center">
              <span className="font-semibold">{date}</span>
              <MdOutlineKeyboardArrowDown />
            </div>
            {openLogIndex === index && (
              <section className="w-full">
                <table className="w-full border rounded-lg bg-custom-white">
                  <thead>
                    <TableHeader TableHeads={TableHeads} />
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td
                          colSpan={TableHeads.length}
                          className="p-3 text-center"
                        >
                          <Skeleton count={5} height="22px" />
                        </td>
                      </tr>
                    ) : (
                      items?.map((log) => (
                        <LogDetails
                          key={log?._id}
                          log={log}
                          BASE_URL={BASE_URL}
                        />
                      ))
                    )}
                  </tbody>
                </table>
              </section>
            )}
          </div>
        ))
      ) : (
        <div>No Data Found</div>
      )}
    </div>
  );
};

export default TaskHistory;
