import React from "react";
const Base_url = process.env.REACT_APP_BASE_URL;

const DashboardBirthdayCard = ({ groupedBirthdays }) => {
  return (
    <div className="border rounded-md p-2 row-span-1">
      <h2 className="font-semibold text-slate-500">Birthday</h2>
      <div className="w-full h-[300px] overflow-auto">
        {Object?.entries(groupedBirthdays)?.length > 0 ? (
          Object?.entries(groupedBirthdays)?.map(([date, birthdays]) => (
            <div key={date}>
              <h2 className="font-semibold text-xs flex items-center my-2">
                {date}
              </h2>

              {birthdays?.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    birthdays?.length - 1 !== index &&
                    "border-b border-[#EFE9CF]"
                  }  w-full py-2 space-y-2 flex items-center gap-4 bg-[#FAFAFA] rounded-md`}
                >
                  <div className="w-10 h-10  rounded-full bg-custom-white">
                    <img
                      src={
                        item?.avatar
                          ? `${Base_url}/${item?.avatar}`
                          : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.name}`
                      }
                      alt={`${item?.name}`}
                      className="w-full h-full rounded-full p-1"
                    />
                  </div>
                  <div className="flex flex-col text-left gap-1">
                    <span className="font-semibold">{item?.name}</span>
                    <span className="text-xs text-slate-400 font-semibold">
                      {item?.designation ? item?.designation : "Admin"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="text-center font-semibold text-sm ">
            No Data available
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardBirthdayCard;
