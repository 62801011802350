import React, { useContext, useEffect, useState } from "react";
import { FaBuilding, FaCheckCircle } from "react-icons/fa";
import { TbXboxXFilled } from "react-icons/tb";
import { CustomChart, FilterSelect, TableHeader } from "../common";
import { apiConnector } from "../../networking/ApiConnector";
import {
  companyEndPoints,
  dashboardEndPoints,
} from "../../networking/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../context";
import { dateFormatter } from "../../helper/formatDate";

import { useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/slice/loadingSlice";
import NoDataAvailable from "../common/NoDataAvailable";
import { Roles } from "../../constant/Constant";
import { DashboardProfileCard } from "./CommonComponent";

const company_labels = [
  "January",
  "Feburary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const CompanyColors = [
  "#ffe0e6",
  "#ffecd9",
  "#fff5dd",
  "#dbf2f2",
  "#d7ecfb",
  "#ebe0ff",
  "#f4f5f5",
  "#5e7272",
  "#83b9df",
  "#e4c57c",
  "#eb8c9f",
  "#d7c9f1",
];
const currentYear = new Date().getFullYear();

const defaultYear = { name: currentYear, value: currentYear };
const yearOptions = [
  { name: currentYear, value: currentYear },
  { name: currentYear - 1, value: currentYear - 1 },
  { name: currentYear - 2, value: currentYear - 2 },
  { name: currentYear - 3, value: currentYear - 3 },
];

const TableHeadsForNewCompany = ["S.No.", "Company Name", "Date"];
const TableHeadsCompanyUsers = ["S.No.", "Company Name", "Total Users"];
const Base_url = process.env.REACT_APP_BASE_URL;
const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, user } = useSelector((state) => state.auth);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [dashboardData, setDashboardData] = useState();
  const [filterYear, setFilterYear] = useState(currentYear);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [company, setCompany] = useState([]);
  const [companyWithMostUsers, setCompanyWithMostUsers] = useState([]);
  let company_registrations = new Array(12).fill(0);

  const updatedBarChart = company_registrations?.map((value, index) => {
    const update = dashboardData?.registration?.find(
      (item) => item?.month == index + 1
    );
    return update ? update?.count : value;
  });
  const GetDashboardDetails = async () => {
    dispatch(setLoading(true));
    try {
      const dashboard_response = await apiConnector(
        "GET",
        `${dashboardEndPoints?.DASHBOARD_API}?year=${filterYear}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      setDashboardData(dashboard_response?.data?.response);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    GetDashboardDetails();
  }, [token, filterYear]);

  const getAllCompanies = async () => {
    setCompanyLoading(true);
    try {
      const companiesResponse = await apiConnector(
        "GET",
        companyEndPoints?.COMPANY_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_size: 5,
          page_number: Math.ceil(dashboardData?.total_companies / 5),
        }
      );
      setCompany(companiesResponse?.data?.data?.companies);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setCompanyLoading(false);
    }
  };
  const getCompaniesWithMostUsers = async () => {
    setCompanyLoading(true);
    try {
      const companiesResponse = await apiConnector(
        "GET",
        dashboardEndPoints?.COMPANY_WITH_MOST_USERS,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          no_of_company: 5,
        }
      );

      setCompanyWithMostUsers(companiesResponse?.data?.response);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setCompanyLoading(false);
    }
  };
  useEffect(() => {
    getAllCompanies();
    getCompaniesWithMostUsers();
  }, []);
  return (
    <div className="w-full h-full py-4 space-y-4">
      <div className="w-full flex gap-4">
        {/* <div className="w-full lg:w-1/3 bg-[#DCF0FF] rounded-md col-span-2 row-span-2 flex flex-col sm:flex-row gap-10 justify-center items-center space-y-1 p-3">
          <div className="w-8 h-8 md:w-14 md:h-14 xl:w-20 xl:h-20 2xl:w-24  2xl:h-24 rounded-full bg-custom-white">
            <img
              src={
                user?.avatar
                  ? `${Base_url}/${user?.avatar}`
                  : `https://api.dicebear.com/5.x/initials/svg?seed=${user?.user_name}`
              }
              alt={`${user?.user_name}`}
              className="w-full h-full rounded-full p-1"
            />
          </div>
          <div className="flex flex-col justify-center items-center space-y-2">
            <span className="font-semibold">{user?.user_name}</span>
            <span className="text-slate-400 capitalize">
              (
              {Object.keys(Roles)
                .find((key) => Roles[key] === user?.roleId)
                .replace("_", " ")}
              )
            </span>
            <span>EmployeeId - {user?.emp_id ?? ""}</span>
          </div>
        </div> */}
        <DashboardProfileCard dashboardData={dashboardData} />
        <section className="w-full lg:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-[#D7FFC5] rounded-md p-4 flex items-center gap-2">
            <div className="w-14 h-14 bg-custom-white border border-green-300 text-custom-status-completed rounded-full text-2xl font-semibold flex justify-center items-center">
              <FaBuilding />
            </div>
            <div className="flex flex-col justify-center">
              <span className="text-sm font-semibold">Total Companies</span>
              <span className="text-xl font-semibold">
                {dashboardData?.total_companies ?? "--"}
              </span>
            </div>
          </div>
          <div className="bg-[#DBF0FF] rounded-md p-4 flex items-center gap-2">
            <div className="w-14 h-14 bg-custom-white border border-blue-300 text-[#0D62A1] rounded-full text-2xl font-semibold flex justify-center items-center">
              <FaCheckCircle />
            </div>
            <div className="flex flex-col justify-center">
              <span className="text-sm font-semibold">Active Companies</span>
              <span className="text-xl font-semibold">
                {dashboardData?.active_companies ?? "--"}
              </span>
            </div>
          </div>
          <div className="bg-[#FFE9C4] rounded-md p-4 flex items-center gap-2">
            <div className="w-14 h-14 bg-custom-white border border-yellow-300 text-[#FFC200] rounded-full text-2xl font-semibold flex justify-center items-center">
              <TbXboxXFilled />
            </div>
            <div className="flex flex-col justify-center">
              <span className="text-sm font-semibold">Inactive Companies</span>
              <span className="text-xl font-semibold">
                {dashboardData?.inactive_companies ?? "--"}
              </span>
            </div>
          </div>
        </section>
      </div>

      <section className="w-full flex flex-col lg:flex-row items-center gap-5">
        <div className="w-full lg:w-2/3 space-y-3">
          <h2 className="font-semibold text-sm">Newly Registered Companies</h2>
          <table className="w-full border rounded-lg bg-custom-white overflow-auto">
            <thead>
              <TableHeader TableHeads={TableHeadsForNewCompany} />
            </thead>

            <tbody>
              {company?.length ? (
                company?.map((item, index) => {
                  return (
                    <tr
                      key={item?._id}
                      className="w-full border-b hover:bg-slate-100 text-sm"
                    >
                      <td className="p-1">{index + 1}</td>
                      <td className="p-1">{item?.company_name}</td>
                      <td className="p-1">
                        {item?.createdAt !== item?.updatedAt
                          ? dateFormatter(item?.updatedAt, {
                              format: "MMM DD, YYYY hh:mm A",
                              includeTimeForToday: true,
                            })
                          : "-"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataAvailable span={3} />
              )}
            </tbody>
          </table>
        </div>
        <div className="w-full lg:w-1/3 space-y-3">
          <h2 className="font-semibold text-sm">Companies With Most Users</h2>
          <table className="w-full border rounded-lg bg-custom-white overflow-auto">
            <thead>
              <TableHeader TableHeads={TableHeadsCompanyUsers} />
            </thead>

            <tbody>
              {companyWithMostUsers?.length ? (
                companyWithMostUsers?.map((item, index) => {
                  return (
                    <tr
                      key={item?._id}
                      className="w-full border-b hover:bg-slate-100 text-sm"
                    >
                      <td className="p-1">{index + 1}</td>
                      <td className="p-1">{item?.company_name}</td>
                      <td className="p-1 flex justify-end pr-3">
                        {item?.user}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataAvailable span={3} />
              )}
            </tbody>
          </table>
        </div>
      </section>
      <section className="w-full flex items-center gap-4 space-y-4">
        <div className="w-full lg:w-1/2 h-full">
          <div className="flex justify-between">
            <h2 className="font-semibold">Company Registrations</h2>
            <FilterSelect
              options={yearOptions}
              icon={false}
              defaultValue={defaultYear}
              onChange={(e) => {
                setFilterYear(e?.value);
              }}
            />
          </div>
          <div className="w-full h-96">
            <CustomChart
              labelName={"Total Companies"}
              chartType="bar"
              labels={company_labels}
              data={updatedBarChart}
              colors={CompanyColors}
              // className="!h-96	!w-full"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 h-full"></div>
      </section>
    </div>
  );
};

export default SuperAdminDashboard;
