import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import departmentReducer from "./slice/departmentSlice";
import employeeReducer from "./slice/employeeSlice";
import projectReducer from "./slice/projectSlice";
import taskReducer from "./slice/taskSlice";
import designationReducer from "./slice/designationSlice";
import officeLocationReducer from "./slice/locationSlice";
import companyReducer from "./slice/companySlice";
import filterReducer from "./slice/filterSlice";
import loadingReducer from "./slice/loadingSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  department: departmentReducer,
  designation: designationReducer,
  officeLocation: officeLocationReducer,
  employee: employeeReducer,
  project: projectReducer,
  task: taskReducer,
  company: companyReducer,
  filter: filterReducer,
  loading: loadingReducer,
});

export default rootReducer;
