import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const CustomChart = ({
  labelName,
  chartType = "",
  labels = [],
  data = [],
  colors = [],
  className = "",
}) => {
  const chartRef = useRef(null);
  let chartInstance = null;

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    let delayed,
      // Initialize the chart
      chartInstance = new Chart(ctx, {
        type: chartType || "pie",

        data: {
          labels: labels,
          datasets: [
            {
              barPercentage: 0.8,
              label: labelName,
              data: data,
              backgroundColor: colors,
              tension: 0.4,
            },
          ],
        },
        options: {
          responsive: true,
          aspectRatio: 2,
          animation: {
            onComplete: () => {
              delayed = true;
            },
            delay: (context) => {
              let delay = 0;
              if (
                context.type === "data" &&
                context.mode === "default" &&
                !delayed
              ) {
                delay = context.dataIndex * 100 + context.datasetIndex * 100;
              }
              return delay;
            },
          },
          plugins: {
            tooltip: {
              usePointStyle: true,
            },
            legend: {
              display: true,
              labels: { usePointStyle: true },
              position: "left",
            },
          },
        },
      });

    // Cleanup chart instance on unmount
    return () => {
      chartInstance.destroy();
    };
  }, [data, chartType, labels, labelName]);

  return <canvas className={className} ref={chartRef}></canvas>;
};

export default CustomChart;
