import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { taskEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const getAllTeamTask = createAsyncThunk(
  "getAllTeamTask",
  async ({
    token,
    project,
    milestone,
    assign_to,
    assign_by,
    status,
    priority,
    currentPage,
    itemsPerPage,
    projectId,
  }) => {
    const queryParams = {};
    if (project) queryParams.project = project;
    if (milestone) queryParams.milestone = milestone;
    if (assign_to) queryParams.assign_to = assign_to;
    if (assign_by) queryParams.assign_by = assign_by;
    if (status) queryParams.status = status;
    if (priority) queryParams.priority = priority;
    if (projectId) queryParams.projectId = projectId;
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    const response = await apiConnector(
      "GET",
      taskEndPoints?.TEAM_TASK_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );

    return response.data;
  }
);
export const getAllPersonalTask = createAsyncThunk(
  "getAllPersonalTask",
  async ({
    token,
    project,
    milestone,
    assign_to,
    sortOrder,
    assign_by,
    status,
    priority,
    currentPage,
    itemsPerPage,
  }) => {
    const queryParams = {};
    if (project) queryParams.project = project;
    if (milestone) queryParams.milestone = milestone;
    if (assign_to) queryParams.assign_to = assign_to;
    if (sortOrder) queryParams.sortOrder = sortOrder;
    if (assign_by) queryParams.assign_by = assign_by;
    if (status) queryParams.status = status;
    if (priority) queryParams.priority = priority;
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    const response = await apiConnector(
      "GET",
      taskEndPoints?.TASK_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );

    return response.data;
  }
);

// Create a slice
const taskSlice = createSlice({
  name: "task",
  initialState: {
    teamTaskData: null,
    personalTaskData: null,
    taskLoading: false,
    taskError: null,
    activeTabInTask: "task_details",
  },
  reducers: {
    setActiveTabInTask(state, value) {
      state.activeTabInTask = value.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTeamTask.pending, (state) => {
        state.taskLoading = true;
        state.teamTaskData = null;
        state.taskError = null;
      })
      .addCase(getAllTeamTask.fulfilled, (state, action) => {
        state.teamTaskData = action.payload;
        state.taskLoading = false;
        state.taskError = null;
      })
      .addCase(getAllTeamTask.rejected, (state, action) => {
        state.taskError = action.error.message;
        state.taskLoading = false;
        state.teamTaskData = null;
      })
      .addCase(getAllPersonalTask.pending, (state) => {
        state.taskLoading = true;
        state.personalTaskData = null;
        state.taskError = null;
      })
      .addCase(getAllPersonalTask.fulfilled, (state, action) => {
        state.personalTaskData = action.payload;
        state.taskLoading = false;
        state.taskError = null;
      })
      .addCase(getAllPersonalTask.rejected, (state, action) => {
        state.taskError = action.error.message;
        state.taskLoading = false;
        state.personalTaskData = null;
      });
  },
});

export const { setActiveTabInTask } = taskSlice.actions;
export default taskSlice.reducer;
