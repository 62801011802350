import React from "react";
import {
  CustomAssignees,
  CustomButton,
  CustomModal,
  CustomPriorityButton,
  CustomStatusButton,
} from "../../common";
import { Heading } from "../../project";
import {
  dateFormatter,
  getDateDifferenceStatus,
} from "../../../helper/formatDate";
import { useSelector } from "react-redux";
import { Roles } from "../../../constant/Constant";

const SubTaskView = ({
  subTask,
  openSubTaskViewModal,
  setOpenSubTaskViewModal,
  setOpenAddSubTaskForm,
}) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <CustomModal
      isOpen={openSubTaskViewModal}
      onClose={() => setOpenSubTaskViewModal(false)}
      classname="w-full md:w-[60%]"
    >
      <div className="font-semibold">SubTask Details</div>
      <div className="w-full p-4">
        <div className="relative font-semibold bg-gradient-custom-light-blue rounded-lg p-2 border">
          <h1 className="w-[88%] text-sm md:text-base text-justify">
            {subTask?.title}
          </h1>
          {user?.roleId == Roles?.admin ||
          user?._id == subTask?.parent_task?.project?.project_manager?._id ||
          subTask?.parent_task?.project?.team_lead?.some(
            (lead) => lead?._id === user?._id
          ) ? (
            <CustomButton
              title={"Edit SubTask"}
              buttonType={"button"}
              onClick={() => {
                setOpenAddSubTaskForm(true);
                setOpenSubTaskViewModal(false);
              }}
              classname="absolute right-0 top-0 underline text-[#2E6790] px-5 py-1 rounded-md"
            />
          ) : (
            ""
          )}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-10 my-5">
          <Heading label="Task ID" value={subTask?.parent_task?.taskId} />
          <Heading label="Task Name" value={subTask?.title} />
          <Heading
            label="Start Date"
            value={
              subTask?.start_date
                ? dateFormatter(subTask?.start_date, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })
                : "--"
            }
          />
          <Heading
            label="Deadline(Tentative)"
            value={
              subTask?.due_date ? (
                <>
                  {dateFormatter(subTask?.due_date, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })}{" "}
                  ({getDateDifferenceStatus(subTask?.due_date)})
                </>
              ) : (
                "--"
              )
            }
          />
          <Heading
            label="Completed ON"
            value={
              subTask?.completed_on
                ? dateFormatter(subTask?.completed_on, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })
                : "--"
            }
          />
          <Heading
            label="Estimated Time"
            value={subTask?.estimated_time ?? "--"}
          />
          <Heading label="Hours Logged" value={subTask?.hours_logged ?? "--"} />
          <Heading
            label="Status"
            value={<CustomStatusButton selectedStatus={subTask?.status} />}
          />
          <Heading
            label="Priority"
            value={<CustomPriorityButton priority={subTask?.priority} />}
          />

          <Heading
            label="Assignees"
            value={<CustomAssignees assignees={subTask?.assign_to} />}
          />
        </div>
        <Heading label="Description" value={subTask?.description} />
      </div>
    </CustomModal>
  );
};

export default SubTaskView;
