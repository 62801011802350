import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CommonTooltip,
  CustomAssignees,
  CustomButton,
  CustomInput,
  CustomModal,
  CustomSelect,
  CustomStatusButton,
  CustomStatusDropdown,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../../common";
import {
  dateFormatter,
  getDateDifferenceStatus,
} from "../../../helper/formatDate";
import { IoMdSearch } from "react-icons/io";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle, FaRegQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import useOnClickOutside from "../../../helper/onClickOutside";
import AddSubTask from "./AddSubTask";
import { apiConnector } from "../../../networking/ApiConnector";
import { subTaskEndPoints } from "../../../networking/Endpoints";
import { setLoading } from "../../../redux/slice/loadingSlice";
import { IoSwapHorizontal } from "react-icons/io5";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SubTaskView from "./SubTaskView";
import { toast } from "react-toastify";
import { GrView } from "react-icons/gr";
import { CustomAlertContext } from "../../../context";
import { Roles } from "../../../constant/Constant";
const baseUrl = process.env.REACT_APP_BASE_URL;
const statusUpdateFormValidation = yup.object().shape({
  hours_logged: yup
    .string()
    .matches(
      /^(?:\d{2}|\d{3}):([0-5]\d)$/,
      "Hours Logged must be in the format HH:MM"
    )
    .required("Hours Logged is required"),
  completed_on: yup.string().required("Completed_on is required"),
  completed_by: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .required("Completed_by is required"),
});
const SubTask = ({
  taskActiveTab = "task_details",
  task_id = "",
  start_date = "",
  due_date = "",
  taskMembers = [],
  showButton,
  taskAssignees,
}) => {
  const actionRef = useRef(),
    statusRef = useRef(),
    statusModalRef = useRef(),
    dispatch = useDispatch(),
    { setToastNotification } = useContext(CustomAlertContext),
    { token, user } = useSelector((state) => state.auth),
    [subTaskLoading, setSubtaskLoading] = useState(false),
    [openAddSubTaskForm, setOpenAddSubTaskForm] = useState(false),
    [openSubTaskViewModal, setOpenSubTaskViewModal] = useState(false),
    [currentPage, setCurrentPage] = useState(1),
    [itemsPerPage, setItemsPerPage] = useState(100),
    [openDropdownIndex, setOpenDropdownIndex] = useState(null),
    [searchString, setSearchString] = useState(""),
    [statusIndex, setStatusIndex] = useState(""),
    [openStatus, setOpenStatus] = useState(false),
    [statusModal, setStatusModal] = useState(false),
    [selectedStatus, setSelectedStatus] = useState(""),
    [subTask, setSubTask] = useState(null),
    [subTasks, setSubTasks] = useState([]),
    [tableData, setTableData] = useState([]),
    [subTaskCount, setSubTaskCount] = useState(0),
    [subTaskId, setSubTaskId] = useState(""),
    [subTaskAssignees, setSubTaskAssignees] = useState([]);
    

  const handleStatusChange = async (newStatus) => {
    setSelectedStatus(newStatus);
    setStatusModal(true);
    setOpenStatus(false);
  };
  const TableHeads = [
    "SubTask ID",
    "Name",
    "Start Date",
    "Due Date",
    "Estimated Time",
    "Hours Logged",
    "Assign To",
    "Status",
    "Actions",
  ];

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(statusUpdateFormValidation) });
  useEffect(() => {
    reset({
      hours_logged: subTask ? subTask?.hours_logged : undefined,
      completed_on: subTask ? subTask?.completed_on : undefined,
      completed_by: subTask ? subTask?.completed_by : undefined,
    });
  }, [subTask]);
  useOnClickOutside(actionRef, () => setOpenDropdownIndex(null));
  useOnClickOutside(statusRef, () => setStatusIndex(null));
  useOnClickOutside(statusModalRef, () => setStatusModal(false));

  const updateSubTaskStatusHandler = async (data) => {
    dispatch(setLoading(true));
    try {
      const sub_task_response = await apiConnector(
        "PUT",
        `${subTaskEndPoints?.SUBTASK_API}/${subTaskId}`,
        selectedStatus === "Completed" ? data : { status: selectedStatus },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success(sub_task_response?.data?.message);
      fetchSubTasks();
      setStatusModal(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const fetchSubTasks = async () => {
    setSubtaskLoading(true);
    try {
      const sub_task_response = await apiConnector(
        "GET",
        `${subTaskEndPoints?.SUBTASK_API}/${task_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_number: currentPage,
          page_size: itemsPerPage,
        }
      );
      setSubTasks(sub_task_response?.data?.data?.sub_tasks);
      setTableData(sub_task_response?.data?.data?.sub_tasks);
      setSubTaskCount(sub_task_response?.data?.data?.subtask_count);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setSubtaskLoading(false);
    }
  };
  useEffect(() => {
    if (task_id) {
      fetchSubTasks();
    }
  }, [task_id, currentPage, itemsPerPage]);

  const pageCount = Math.ceil(subTaskCount / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  const onSubmitHandler = (data) => {
    const payload = {
      status: selectedStatus,
      hours_logged: data?.hours_logged ? data?.hours_logged : undefined,
      completed_on: data?.completed_on ? data?.completed_on : undefined,
      completed_by: data?.completed_by ? data?.completed_by : [],
    };
    updateSubTaskStatusHandler(payload);
  };
  return (
    <div className="w-full bg-custom-white rounded-md p-4">
      {openAddSubTaskForm ? (
        <AddSubTask
          taskId={task_id}
          start_date={start_date}
          due_date={due_date}
          subTask={subTask}
          setSubTask={setSubTask}
          setOpenAddSubTaskForm={setOpenAddSubTaskForm}
          taskMembers={taskMembers}
        />
      ) : (
        <>
          <section className="flex sm:flex-row flex-col justify-between items-center py-2 rounded-md">
            <h1 className="font-semibold">SubTask List</h1>
            <div className="flex flex-col md:flex-row justify-center items-center gap-4">
              <div className="relative ">
                <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
                <input
                  name="searchString"
                  label=""
                  type="text"
                  placeholder="Search"
                  // onChange={(e) => filterProject(e)}
                  // value={searchString}
                  className="bg-custom-white px-6 py-2 font-semibold text-sm border outline-none rounded-md"
                />
              </div>
              <CustomButton
                title={
                  <div className="flex justify-center items-center font-semibold">
                    <MdOutlineFilterAlt size={20} />
                    <span>Filters</span>
                  </div>
                }
                buttonType="submit"
                classname="bg-custom-white text-slate-400 p-2 font-semibold text-sm rounded-md border"
              />
              {showButton ||
              taskAssignees?.some((item) => item?._id == user?._id) ? (
                <CustomButton
                  title={
                    <div className="flex justify-center items-center gap-2 ">
                      <FaPlusCircle />
                      <span>Add SubTask</span>
                    </div>
                  }
                  buttonType="button"
                  onClick={() => setOpenAddSubTaskForm(true)}
                  classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
                />
              ) : (
                ""
              )}
            </div>
          </section>
          <section className="w-full overflow-auto">
            <table className="w-full border rounded-lg">
              {subTaskLoading ? (
                <Skeleton count={10} width={"100%"} />
              ) : tableData && tableData?.length > 0 ? (
                <>
                  <thead>
                    <TableHeader TableHeads={TableHeads} />
                  </thead>
                  <tbody>
                    {tableData?.map((item, index) => {
                      return (
                        <tr
                          key={item?._id}
                          className="w-full border-b hover:bg-slate-100"
                        >
                          <td className="p-2 text-sm">
                            {item?.parent_task?.taskId + "." + (index + 1)}
                          </td>
                          <td className="p-2 text-sm truncate">
                            <div
                              onClick={() => {
                                setSubTask(item);
                                setOpenSubTaskViewModal(true);
                              }}
                              className="font-semibold cursor-pointer"
                            >
                              {item?.title}
                            </div>
                          </td>
                          <td className="p-2 text-sm">
                            {item?.start_date
                              ? dateFormatter(item?.start_date, {
                                  format: "MMM DD, YYYY",
                                })
                              : "--"}
                          </td>
                          {/* <td className="p-2 text-sm">
                            {item?.due_date ? (
                              <>
                                {dateFormatter(item?.due_date, {
                                  format: "MMM DD, YYYY",
                                  includeTimeForToday: false,
                                })}{" "}
                                (
                                {getDateDifferenceStatus(
                                  item?.due_date,
                                  item?.status === "Completed"
                                    ? item?.completed_on
                                    : null
                                )}
                                )
                              </>
                            ) : (
                              "--"
                            )}
                          </td> */}{" "}
                          <td className="px-2 text-sm">
                            {item?.due_date ? (
                              <div className="flex items-center gap-2">
                                <span
                                  className={`${
                                    new Date() >
                                      new Date(
                                        item?.status !== "Completed"
                                          ? item?.due_date
                                          : item?.completed_on
                                      ) && item?.status !== "Completed"
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {dateFormatter(item?.due_date, {
                                    format: "MMM DD, YYYY",
                                    includeTimeForToday: false,
                                  })}
                                </span>
                                <CommonTooltip
                                  tooltip={getDateDifferenceStatus(
                                    item?.due_date,
                                    item?.status === "Completed"
                                      ? item?.completed_on
                                      : null
                                    // false
                                  )}
                                  tooltipClass={
                                    "bg-custom-white dark:bg-custom-white text-lg"
                                  }
                                >
                                  <FaRegQuestionCircle className="text-slate-400" />
                                </CommonTooltip>
                              </div>
                            ) : (
                              "--"
                            )}
                          </td>
                          <td className="p-2 text-sm">
                            {item?.estimated_time
                              ? item?.estimated_time + " Hrs"
                              : "--"}
                          </td>
                          <td className="p-2 text-sm">
                            {item?.hours_logged
                              ? item?.hours_logged + " Hrs"
                              : "--"}
                          </td>
                          <td className="px-2 text-sm">
                            {<CustomAssignees assignees={item?.assign_to} /> ??
                              "--"}
                          </td>
                          <td
                            onClick={() => {
                              if (
                                user?.roleId === Roles?.admin ||
                                user?._id ==
                                  item?.parent_task?.project?.project_manager
                                    ?._id ||
                                item?.parent_task?.project?.team_lead?.some(
                                  (lead) => lead?._id == user?._id
                                ) ||
                                // user?.roleId === Roles?.team_lead ||
                                item?.assign_to?.find(
                                  (element) => element?._id === user?._id
                                )
                              ) {
                                setStatusIndex(index);
                                setOpenStatus(!openStatus);
                                setSubTaskId(item?._id);
                                setSubTask(item);
                                setSubTaskAssignees(item?.assign_to);
                              }
                            }}
                            className={`p-2 text-sm`}
                          >
                            <div
                              className={`relative cursor-pointer w-fit rounded-lg flex justify-center items-center gap-2`}
                            >
                              <CustomStatusButton
                                selectedStatus={item?.status}
                                isDropdown={true}
                              />
                              {openStatus && statusIndex === index && (
                                <CustomStatusDropdown
                                  statusRef={statusRef}
                                  handleStatusChange={handleStatusChange}
                                />
                              )}
                            </div>
                          </td>
                          <td
                            onClick={() =>
                              setOpenDropdownIndex(
                                openDropdownIndex === index ? null : index
                              )
                            }
                            className=" cursor-pointer"
                          >
                            <div className="flex justify-center items-center gap-2 p-2">
                              <div
                                onClick={() => {
                                  setSubTask(item);
                                  setOpenSubTaskViewModal(true);
                                }}
                                className="p-1 border border-custom-blue rounded-md cursor-pointer"
                              >
                                <GrView className="text-custom-blue" />
                              </div>
                              {user?.roleId === Roles?.admin ||
                              user?._id ==
                                item?.parent_task?.project?.project_manager
                                  ?._id ||
                              item?.parent_task?.project?.team_lead?.some(
                                (lead) => lead?._id === user?._id
                              ) ||
                              item?.assign_to?.find(
                                (element) => element?._id === user?._id
                              ) ? (
                                <div
                                  onClick={() => {
                                    setSubTask(item);
                                    setOpenAddSubTaskForm(true);
                                  }}
                                  className="p-1 border border-custom-blue rounded-md cursor-pointer"
                                >
                                  <MdOutlineEdit className="text-custom-blue" />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan={TableHeads.length}
                      className="text-center font-semibold text-sm p-4"
                    >
                      <NoDataFound />
                    </td>
                  </tr>
                </tbody>
              )}
              <tfoot className="relative">
                <tr>
                  <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                    <PaginationText
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      dataCount={subTaskCount}
                      dataLength={tableData?.length}
                    />
                  </td>
                  <td className="absolute right-2 top-1.5">
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageRangeDisplayed={itemsPerPage}
                      pageCount={pageCount}
                      name={"pageSize"}
                      onChange={(e) => setItemsPerPage(e?.target?.value)}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </section>
        </>
      )}
      {openSubTaskViewModal && (
        <SubTaskView
          subTask={subTask}
          openSubTaskViewModal={openSubTaskViewModal}
          setOpenSubTaskViewModal={setOpenSubTaskViewModal}
          setOpenAddSubTaskForm={setOpenAddSubTaskForm}
        />
      )}
      {statusModal && (
        <CustomModal
          isOpen={statusModal}
          onClose={() => setStatusModal(false)}
          ref={statusModalRef}
        >
          {selectedStatus === "Completed" ? (
            <form
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
              className="w-full"
            >
              <div className="my-2 sm:my-4 ">
                <CustomInput
                  name="hours_logged"
                  label="Hours Logged"
                  inputType="text"
                  placeholder="00:00"
                  control={control}
                  required={true}
                  error={errors?.hours_logged?.message}
                />
              </div>
              <div className="my-2 sm:my-4 ">
                <CustomInput
                  name="completed_on"
                  label="Completed On"
                  inputType="date"
                  placeholder="Select..."
                  control={control}
                  required={true}
                  error={errors?.completed_on?.message}
                />
              </div>
              <div className="my-2 sm:my-4 ">
                <CustomSelect
                  name="completed_by"
                  label="Completed By"
                  inputType="text"
                  placeholder="Select..."
                  control={control}
                  multiple={true}
                  required={true}
                  options={subTaskAssignees?.map((member) => ({
                    name: member?._id === user?._id ? "You" : member?.user_name,
                    value: member?._id,
                    designation: member?.designation?.designation,
                    avatar: member?.avatar
                      ? `${baseUrl}/${member?.avatar}`
                      : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
                  }))}
                  classname="bg-custom-white"
                />
              </div>
              <div className="flex justify-end items-center gap-5 mt-5">
                <CustomButton
                  title={"Cancel"}
                  onClick={() => setStatusModal(false)}
                  buttonType={"button"}
                  classname={"px-2 py-1 bg-custom-white border rounded-md "}
                />
                <CustomButton
                  title={"Continue"}
                  buttonType={"submit"}
                  classname={
                    "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                  }
                />
              </div>
            </form>
          ) : (
            <>
              <div className="text-center text-custom-black">
                <h2 className="text-2xl font-bold my-4">Change Status</h2>
              </div>
              <div className="w-20 h-20 bg-[#C3E6FF] p-2 rounded-full flex justify-center items-center">
                <div className="bg-custom-blue rounded-full w-16 h-16 flex justify-center items-center">
                  <IoSwapHorizontal size={32} className="text-custom-white" />
                </div>
              </div>
              <div className="text-center text-[#747474]">
                <h2 className="font-medium my-4">
                  are you sure you want to change status?
                </h2>
              </div>

              <div className="flex justify-end items-center gap-5 mt-5">
                <CustomButton
                  title={"Cancel"}
                  onClick={() => setStatusModal(false)}
                  buttonType={"button"}
                  classname={"px-2 py-1 bg-custom-white border rounded-md "}
                />
                <CustomButton
                  title={"Continue"}
                  onClick={() => {
                    updateSubTaskStatusHandler();
                  }}
                  buttonType={"button"}
                  classname={
                    "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                  }
                />
              </div>
            </>
          )}
        </CustomModal>
      )}
    </div>
  );
};

export default SubTask;
