import { Route, Routes } from "react-router-dom";
import "./App.css";
import { CreateNewPasswordPage, ForgotPassword, Home, Login } from "./pages";
import Dashboard from "./components/dashboard/Dashboard";
import {
  CreateCompanyForm,
  Company,
  CompanyView,
} from "./components/super_admin";
import { useSelector } from "react-redux";
import { Error, OpenRoute, PrivateRoute, Profile } from "./components/common";
import {
  CompanyProfile,
  Department,
  Designation,
  OfficeLocation,
} from "./components/admin";
import { Roles } from "./constant/Constant";
import { Project, ProjectForm, ProjectView } from "./components/project";
import { Employees, EmployeesForm, EmployeeView } from "./components/employee";
import Calender from "./components/project_manager/Calender";
import Report from "./components/project_manager/Report";
import Timesheets from "./components/project_manager/Timesheets";
import { PersonalTask, TaskForm, TaskView } from "./components/task";
import ProjectOverview from "./components/project/ProjectOverview";
import LandingPage from "./pages/LandingPage";
import Products from "./components/products/Products";
import AddProduct from "./components/products/AddProduct";

function App() {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="w-[100vw] h-[100vh]">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <OpenRoute>
              <LandingPage />
            </OpenRoute>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />
        <Route
          exact
          path="/forgot-password"
          element={
            <OpenRoute>
              <ForgotPassword />
            </OpenRoute>
          }
        />
        <Route
          exact
          path="/forgot-password/:password_token"
          element={
            <OpenRoute>
              <CreateNewPasswordPage />
            </OpenRoute>
          }
        />
        <Route
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        >
          <Route exact path="/dashboard" element={<Dashboard />} />

          {/* super_admin routes */}
          {user?.roleId === Roles?.super_admin && (
            <>
              <Route exact path="/company" element={<Company />} />
              <Route
                exact
                path="/company/create"
                element={<CreateCompanyForm />}
              />
              <Route exact path="/company-view" element={<CompanyView />} />
            </>
          )}
          {/* admin routes */}
          {user?.roleId === Roles?.admin && (
            <>
              <Route exact path="/department" element={<Department />} />
              <Route exact path="/designation" element={<Designation />} />
              <Route exact path="/our_products" element={<Products />} />
              <Route exact path="/add_products" element={<AddProduct />} />
              <Route
                exact
                path="/office_location"
                element={<OfficeLocation />}
              />
              <Route
                exact
                path="/company-profile"
                element={<CompanyProfile />}
              />
            </>
          )}
          {user?.roleId !== Roles?.super_admin && (
            <>
              <Route exact path="/task" element={<PersonalTask />} />
              <Route exact path="/task/create" element={<TaskForm />} />
              <Route exact path="/task/view" element={<TaskView />} />
              <Route exact path="/project" element={<Project />} />
              <Route exact path="/overview" element={<ProjectOverview />} />
              <Route exact path="/project/create" element={<ProjectForm />} />
              <Route exact path="/project/view" element={<ProjectView />} />
              <Route exact path="/employees" element={<Employees />} />
              <Route
                exact
                path="/employees/create"
                element={<EmployeesForm />}
              />
              <Route exact path="/employees/view" element={<EmployeeView />} />
            </>
          )}
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/calender" element={<Calender />} />
          <Route exact path="/report" element={<Report />} />
          <Route exact path="/timesheets" element={<Timesheets />} />
        </Route>
        <Route exact path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
