import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

// Constants
const DEFAULT_TIMEZONE = "Asia/Kolkata";
const DEFAULT_FORMAT = "MMM DD, YYYY";

// Normalize Date for Safari Compatibility
function parseDate(dateString) {
  return dayjs.utc(dateString).tz(DEFAULT_TIMEZONE);
}

// Date Formatter Function
export function dateFormatter(
  dateString,
  options = { format: DEFAULT_FORMAT, includeTimeForToday: true }
) {
  const date = parseDate(dateString);

  if (!date.isValid()) {
    return "Invalid Date";
  }

  const { format, includeTimeForToday } = options;

  if (date.isToday()) {
    return includeTimeForToday ? `Today ${date.format("HH:mm A")}` : `Today`;
  } else if (date.isYesterday()) {
    return includeTimeForToday
      ? `Yesterday ${date.format("HH:mm A")}`
      : "Yesterday";
  }

  return date.format(format);
}

// Group by Date Function
export const groupByDate = (items ,field='createdAt') => {
  if (!items || items.length === 0) return {};

  return items.reduce((acc, note) => {
    const noteDate = parseDate(note[field]);

    if (!noteDate.isValid()) {
      return acc;
    }

    let displayDate;
    if (noteDate.isToday()) {
      displayDate = "Today";
    } else if (noteDate.isYesterday()) {
      displayDate = "Yesterday";
    } else {
      displayDate = noteDate.format(DEFAULT_FORMAT);
    }

    if (!acc[displayDate]) {
      acc[displayDate] = [];
    }
    acc[displayDate].push(note);
    return acc;
  }, {});
};

// Get Date Difference Status Function
export function getDateDifferenceStatus(
  dueDate,
  completedDate = null,
  textColor = true
) {
  const due = dayjs(dueDate).startOf("day");
  const referenceDate = completedDate
    ? dayjs(completedDate).startOf("day")
    : dayjs().startOf("day");

  if (!due.isValid() || (completedDate && !referenceDate.isValid())) {
    return (
      <span className="text-red-500 text-xs font-semibold">Invalid Date</span>
    );
  }

  const daysDiff = referenceDate.diff(due, "day");
  let outputText;

  const formatDifference = (days) => {
    if (Math.abs(days) <= 6) {
      return `${Math.abs(days)} days`;
    } else if (Math.abs(days) <= 29) {
      const weeks = Math.floor(Math.abs(days) / 7);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"}`;
    } else if (Math.abs(days) <= 364) {
      const months = Math.floor(Math.abs(days) / 30);
      return `${months} ${months === 1 ? "month" : "months"}`;
    } else {
      const years = Math.floor(Math.abs(days) / 365);
      return `${years} ${years === 1 ? "year" : "years"}`;
    }
  };

  if (completedDate) {
    if (daysDiff < 0) {
      outputText = `Completed before ${formatDifference(daysDiff)}`;
    } else if (daysDiff === 0) {
      outputText = "Completed on the same day";
    } else {
      outputText = `Completed after ${formatDifference(daysDiff)}`;
    }
  } else {
    if (daysDiff < 0) {
      outputText = `Time remaining: ${formatDifference(daysDiff)}`;
    } else if (daysDiff === 0) {
      outputText = "Due today";
    } else {
      outputText = `Missed by ${formatDifference(daysDiff)} ago.`;
    }
  }

  const colorClass =
    (!completedDate && daysDiff >= 0) || (completedDate && daysDiff > 0)
      ? "text-red-500"
      : "text-green-500";

  return (
    <span className={`${textColor ? colorClass : ""} text-xs font-semibold`}>
      {outputText}
    </span>
  );
}
