const NoDataAvailable = (props) => {
  return (
    <tr>
      <td
        className="px-6 py-4 text-sm text-gray-500 text-center "
        colSpan={props?.span}
      >
        <p>No data available</p>
      </td>
    </tr>
  );
};

export default NoDataAvailable;
