const BASE_URL = process.env.REACT_APP_BASE_URL;

export const authEndPoints = {
  LOGIN_API: BASE_URL + "/auth/login",
  FORGOT_PASSWORD_API: BASE_URL + "/auth/forgot-password",
  UPDATE_PASSWORD_API: BASE_URL + "/auth/update-password",
};

export const adminEndPoints = {
  CREATE_DEPARTMENT_API: BASE_URL + "/admin/department/create",
  UPDATE_DEPARTMENT_API: BASE_URL + "/admin/department",
  DELETE_DEPARTMENT_API: BASE_URL + "/admin/department",
  DELETE_PROJECT_API: BASE_URL + "/admin/project",
  CREATE_DESIGNATION_API: BASE_URL + "/admin/designation",
  DELETE_DESIGNATION_API: BASE_URL + "/admin/designation",
  OFFICE_LOCATION_API: BASE_URL + "/admin/officelocation",
};

export const companyEndPoints = {
  COMPANY_API: BASE_URL + "/company",
};
export const departmentEndPoints = {
  DEPARTMENT_API: BASE_URL + "/department",
};
export const designationEndPoints = {
  DESIGNATION_API: BASE_URL + "/designation",
};
export const officeLocationEndPoints = {
  OFFICE_LOCATION_API: BASE_URL + "/office",
};

export const employeeEndPoints = {
  EMPLOYEE_API: BASE_URL + "/employee",
  EMPLOYEE_PROFILE_API: BASE_URL + "/employee/profile",
  EMPLOYEE_CHANGE_PASSWORD_API: BASE_URL + "/employee/changepassword",
};

export const projectEndPoints = {
  PROJECT_API: BASE_URL + "/project",
  PROJECT_DASHBOARD_API: BASE_URL + "/project/dashboard",
  PROJECT_LOGS_API: BASE_URL + "/project/log",
  PROJECT_DETAILS_API: BASE_URL + "/project/details",
};

export const taskEndPoints = {
  TASK_API: BASE_URL + "/task",
  TEAM_TASK_API: BASE_URL + "/task/team",
  LOGS_API: BASE_URL + "/task/log",
};

export const subTaskEndPoints = {
  SUBTASK_API: BASE_URL + "/sub-task",
};

export const notesEndPoints = {
  NOTES_API: BASE_URL + "/notes",
  PRIVATE_NOTES_API: BASE_URL + "/notes/private",
};
export const commentEndPoints = {
  COMMENT_API: BASE_URL + "/comment",
};
export const milestoneEndPoints = {
  MILESTONE_API: BASE_URL + "/project/milestone",
};

export const documentEndPoints = {
  DOCUMENT_API: BASE_URL + "/files",
};
export const dashboardEndPoints = {
  DASHBOARD_API: BASE_URL + "/dashboard",
  COMPANY_WITH_MOST_USERS: BASE_URL + "/dashboard/company",
};

export const productEndPoints = {
  PRODUCT_API: BASE_URL + "/product",
};
