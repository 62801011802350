// // import React, { useState, useRef } from "react";
// // import { Controller } from "react-hook-form";
// // import { BsInfoCircle } from "react-icons/bs";
// // import { FiUploadCloud } from "react-icons/fi";
// // import CommonTooltip from "./CommonTooltip";
// // import { tooltip } from "../../constant/TooltipContent";
// // import { useDropzone } from "react-dropzone";

// // const CustomFileUpload = ({
// //   name = "",
// //   label = "",
// //   docs = false,
// //   control,
// //   required = false,
// //   error,
// //   placeholder = "",
// //   labelClassName = "block text-custom-black",
// //   classname = "",
// //   isEditable = false,
// //   setValue,
// //   pdf = false,
// //   video = false,
// //   viewData = null,
// //   editData = null,
// // }) => {
// //   const tooltipContent = tooltip[name];
// //   const [selectedFile, setSelectedFile] = useState(null);
// //   const [previewSource, setPreviewSource] = useState(
// //     viewData ? viewData : editData ? editData : ""
// //   );
// //   const inputRef = useRef(null);

// //   const onDrop = (acceptedFiles) => {
// //     const file = acceptedFiles[0];
// //     if (file) {
// //       previewFile(file);
// //       setSelectedFile(file);
// //       setValue(name, file);
// //     }
// //   };

// //   const { getInputProps, isDragActive } = useDropzone({
// //     accept: pdf
// //       ? {
// //           "application/pdf": [".pdf"],
// //           "application/vnd.ms-excel": [".xls", ".xlsx"],
// //           "application/msword": [".doc", ".docx"],
// //           "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
// //         }
// //       : video
// //       ? { "video/*": [".mp4"] }
// //       : { "image/*": [".jpeg", ".jpg", ".png", ".svg", ".webp", ".gif"] },
// //     onDrop,
// //     noClick: true, // Disable automatic click behavior
// //   });

// //   const previewFile = (file) => {
// //     const reader = new FileReader();
// //     reader.readAsDataURL(file);
// //     reader.onloadend = () => {
// //       setPreviewSource(reader.result);
// //       setValue(name, selectedFile);
// //     };
// //   };

// //   const handleClick = () => {
// //     inputRef.current?.click();
// //   };

// //   return (
// //     <>
// //       <label
// //         htmlFor={name}
// //         className={`flex items-center gap-2 font-medium text-sm text-slate-600 ${labelClassName}`}
// //       >
// //         <span>
// //           {label}
// //           {required && <span className="text-red-500"> *</span>}
// //         </span>
// //         {tooltipContent && (
// //           <CommonTooltip tooltip={tooltipContent}>
// //             <BsInfoCircle />
// //           </CommonTooltip>
// //         )}
// //       </label>

// //       <div
// //         onClick={handleClick} // Ensure click on the div triggers the file picker
// //         className={`${
// //           isDragActive ? "bg-richblack-600" : "bg-richblack-700"
// //         } flex min-h-[150px] cursor-pointer items-center justify-center rounded-md border-2 border-dotted`}
// //       >
// //         <Controller
// //           control={control}
// //           name={name}
// //           render={({ field: { onChange } }) => (
// //             <>
// //               <input
// //                 {...getInputProps()}
// //                 ref={inputRef}
// //                 name={name}
// //                 placeholder={placeholder}
// //                 type="file"
// //                 onChange={(e) => {
// //                   const file = e.target.files[0];
// //                   if (file) {
// //                     previewFile(file);
// //                     setSelectedFile(file);
// //                     onChange(file);
// //                   }
// //                 }}
// //                 accept={
// //                   pdf
// //                     ? "application/pdf, application/vnd.ms-excel, application/msword, application/vnd.ms-powerpoint"
// //                     : "image/jpeg, image/jpg, image/png, image/svg+xml, image/webp, image/gif"
// //                 }
// //                 className="hidden"
// //               />
// //               {previewSource ? (
// //                 <div className="flex w-full flex-col p-6">
// //                   <img
// //                     src={previewSource}
// //                     alt="Preview"
// //                     className="h-full w-full rounded-md object-cover"
// //                   />
// //                   {!viewData && (
// //                     <button
// //                       type="button"
// //                       onClick={() => {
// //                         setPreviewSource("");
// //                         setSelectedFile(null);
// //                         onChange(null);
// //                       }}
// //                       className="mt-3 text-richblack-400 underline"
// //                     >
// //                       Cancel
// //                     </button>
// //                   )}
// //                 </div>
// //               ) : (
// //                 <div className="flex w-full flex-col items-center p-6">
// //                   <div className="grid aspect-square w-14 place-items-center rounded-full bg-pure-greys-800">
// //                     <FiUploadCloud className="text-2xl text-custom-blue" />
// //                   </div>
// //                   <p className="mt-2 max-w-[200px] text-center text-sm text-richblack-200">
// //                     Drag and drop a file, or click to{" "}
// //                     <span className="font-semibold text-yellow-50">Browse</span>
// //                   </p>
// //                   <ul className="mt-10 flex list-disc justify-between space-x-12 text-center text-xs text-richblack-200">
// //                     {pdf || video ? (
// //                       <li>Supported formats: PDF, Word, Excel, or MP4</li>
// //                     ) : (
// //                       <>
// //                         <li>Aspect ratio 16:9</li>
// //                         <li>Recommended size 1024x576</li>
// //                       </>
// //                     )}
// //                   </ul>
// //                 </div>
// //               )}
// //             </>
// //           )}
// //         />
// //       </div>

// //       {error && (
// //         <span className="ml-2 text-xs tracking-wide text-pink-200">
// //           {label} is required
// //         </span>
// //       )}
// //     </>
// //   );
// // };

// // export default CustomFileUpload;

// import React, { useState, useRef } from "react";
// import { Controller } from "react-hook-form";
// import { BsInfoCircle } from "react-icons/bs";
// import { FiUploadCloud } from "react-icons/fi";
// import {
//   AiOutlineFilePdf,
//   AiOutlineFileWord,
//   AiOutlineFileExcel,
//   AiOutlineFilePpt,
// } from "react-icons/ai"; // Icons for file types
// import CommonTooltip from "./CommonTooltip";
// import { tooltip } from "../../constant/TooltipContent";
// import { useDropzone } from "react-dropzone";

// const CustomFileUpload = ({
//   name = "",
//   label = "",
//   control,
//   required = false,
//   error,
//   placeholder = "",
//   labelClassName = "block text-custom-black",
//   pdf = false,
//   setValue,
// }) => {
//   const tooltipContent = tooltip[name];
//   const [previewSource, setPreviewSource] = useState("");
//   const [fileType, setFileType] = useState("");

//   const inputRef = useRef(null);

//   // Handle file drop
//   const onDrop = (acceptedFiles) => {
//     const file = acceptedFiles[0];
//     if (file) {
//       previewFile(file);
//       setValue(name, file); // Set file value in the parent form
//       setFileType(file.type); // Save file type for rendering preview
//     }
//   };

//   const { getInputProps, isDragActive } = useDropzone({
//     accept: pdf
//       ? "application/pdf, application/vnd.ms-excel, application/msword, application/vnd.ms-powerpoint"
//       : "image/*",
//     onDrop,
//     noClick: true, // Disable automatic click behavior for Dropzone
//   });

//   // Handle preview file
//   const previewFile = (file) => {
//     if (file.type.startsWith("image/")) {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onloadend = () => {
//         setPreviewSource(reader.result);
//       };
//     } else {
//       setPreviewSource(null); // Non-image files will not have a preview source
//     }
//   };

//   // Render file type icon
//   const renderFileIcon = () => {
//     switch (fileType) {
//       case "application/pdf":
//         return <AiOutlineFilePdf className="text-red-500 text-5xl" />;
//       case "application/msword":
//       case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
//         return <AiOutlineFileWord className="text-blue-500 text-5xl" />;
//       case "application/vnd.ms-excel":
//       case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
//         return <AiOutlineFileExcel className="text-green-500 text-5xl" />;
//       case "application/vnd.ms-powerpoint":
//       case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
//         return <AiOutlineFilePpt className="text-orange-500 text-5xl" />;
//       default:
//         return <p className="text-gray-400">Preview not available</p>;
//     }
//   };

//   // Manually trigger file input click
//   const handleClick = () => {
//     inputRef.current?.click();
//   };

//   return (
//     <div>
//       <label
//         htmlFor={name}
//         className={`flex items-center gap-2 font-medium text-sm text-slate-600 ${labelClassName}`}
//       >
//         <span>
//           {label}
//           {required && <span className="text-red-500"> *</span>}
//         </span>
//         {tooltipContent && (
//           <CommonTooltip tooltip={tooltipContent}>
//             <BsInfoCircle />
//           </CommonTooltip>
//         )}
//       </label>

//       <div
//         onClick={handleClick} // Trigger file input on div click
//         className={`${
//           isDragActive ? "bg-richblack-600" : "bg-richblack-700"
//         } flex min-h-[150px] cursor-pointer items-center justify-center rounded-md border-2 border-dotted`}
//       >
//         <Controller
//           control={control}
//           name={name}
//           render={({ field: { onChange } }) => (
//             <>
//               <input
//                 {...getInputProps()}
//                 ref={inputRef}
//                 name={name}
//                 required={required}
//                 type="file"
//                 onChange={(e) => {
//                   const file = e.target.files[0];

//                   if (file) {
//                     previewFile(file);
//                     onChange(file);
//                     setValue(name, file);
//                     setFileType(file.type);
//                   }
//                 }}
//                 accept={
//                   pdf
//                     ? "application/pdf, application/vnd.ms-excel, application/msword, application/vnd.ms-powerpoint"
//                     : "image/*"
//                 }
//                 className="hidden"
//               />
//               {previewSource || fileType ? (
//                 <div className="flex w-full flex-col items-center p-6">
//                   {previewSource ? (
//                     <img
//                       src={previewSource}
//                       alt="Preview"
//                       className="h-full w-full rounded-md object-cover"
//                     />
//                   ) : (
//                     renderFileIcon()
//                   )}
//                   <button
//                     type="button"
//                     onClick={() => {
//                       setPreviewSource("");
//                       setFileType("");
//                       onChange(null);
//                       setValue(name, null);
//                     }}
//                     className="mt-3 text-richblack-400 underline"
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               ) : (
//                 <div className="flex w-full flex-col items-center p-6">
//                   <div className="grid aspect-square w-14 place-items-center rounded-full bg-pure-greys-800">
//                     <FiUploadCloud className="text-2xl text-custom-blue" />
//                   </div>
//                   <p className="mt-2 max-w-[200px] text-center text-sm text-richblack-200">
//                     Drag and drop a file, or click to{" "}
//                     <span className="font-semibold text-yellow-50">Browse</span>
//                   </p>
//                 </div>
//               )}
//             </>
//           )}
//         />
//       </div>

//       {error && (
//         <span className="ml-2 text-xs tracking-wide text-red-500">{error}</span>
//       )}
//     </div>
//   );
// };

// export default CustomFileUpload;
import React, { useState, useRef } from "react";
import { Controller } from "react-hook-form";
import { BsInfoCircle } from "react-icons/bs";
import { FiUploadCloud } from "react-icons/fi";
import {
  AiOutlineFilePdf,
  AiOutlineFileWord,
  AiOutlineFileExcel,
  AiOutlineFilePpt,
  AiOutlineFileDone,
} from "react-icons/ai"; // Icons for file types
import CommonTooltip from "./CommonTooltip";
import { tooltip } from "../../constant/TooltipContent";
import { useDropzone } from "react-dropzone";

const CustomFileUpload = ({
  name = "",
  label = "",
  control,
  required = false,
  error,
  placeholder = "",
  labelClassName = "block text-custom-black",
  pdf = false,
  setValue,
  defaultValue = null,
}) => {
  const tooltipContent = tooltip[name];
  const [previewSource, setPreviewSource] = useState(
    defaultValue ? defaultValue : ""
  );
  const [fileType, setFileType] = useState(
    defaultValue?.file_URL?.split(".")[1]
  );
  const [errorMessage, setErrorMessage] = useState("");

  const inputRef = useRef(null);

  // Handle file drop
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    const validExtensions = pdf
      ? ["pdf", "xls", "xlsx", "doc", "docx", "ppt", "pptx"]
      : ["jpeg", "jpg", "png", "svg", "webp", "gif"];

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      setErrorMessage(
        `Invalid file type. Allowed types are: ${validExtensions.join(", ")}`
      );
      return;
    }

    setErrorMessage(""); // Clear any previous error
    previewFile(file);
    setPreviewSource(file); // Save file for further processing
    setFileType(file.type);
    setValue(name, file);
  };

  // Dropzone configuration
  const { getInputProps, isDragActive } = useDropzone({
    accept: pdf
      ? {
          "application/pdf": [],
          "application/vnd.ms-excel": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
          "application/msword": [],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [],
          "application/vnd.ms-powerpoint": [],
          "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            [],
        }
      : { "image/*": [] },
    onDrop,
    noDragEventsBubbling: false,
    noClick: true,
  });

  // Handle previewing the file
  const previewFile = (file) => {
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewSource(reader.result);
      };
    } else {
      setPreviewSource(null); // Non-image files won't have a preview
    }
  };

  // Render file type icon
  const renderFileIcon = () => {
    switch (fileType) {
      case "pdf":
      case "application/pdf":
        return <AiOutlineFilePdf className="text-red-500 text-5xl" />;
      case "msword":
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <AiOutlineFileWord className="text-blue-500 text-5xl" />;
      case "vnd.ms-excel":
      case "application/vnd.ms-excel":
      case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <AiOutlineFileExcel className="text-green-500 text-5xl" />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/msword":
        return <AiOutlineFileDone className="text-blue-500 text-5xl" />;
      case "vnd.ms-powerpoint":
      case "vnd.openxmlformats-officedocument.presentationml.presentation":
        return <AiOutlineFilePpt className="text-orange-500 text-5xl" />;
      default:
        return <p className="text-gray-400">Preview not available</p>;
    }
  };

  // Handle file input click
  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <div>
      <label
        htmlFor={name}
        className={`flex items-center gap-2 font-medium text-sm text-slate-600 ${labelClassName}`}
      >
        <span>
          {label}
          {required && <span className="text-red-500"> *</span>}
        </span>
        {tooltipContent && (
          <CommonTooltip tooltip={tooltipContent}>
            <BsInfoCircle />
          </CommonTooltip>
        )}
      </label>

      <div
        onClick={handleClick} // Trigger file input on div click
        className={`${
          isDragActive ? "bg-richblack-600" : "bg-richblack-700"
        } flex min-h-[150px] cursor-pointer items-center justify-center rounded-md border-2 border-dotted`}
      >
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange } }) => (
            <>
              <input
                {...getInputProps()}
                ref={inputRef}
                name={name}
                required={required}
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (!file) return;

                  const validExtensions = pdf
                    ? ["pdf", "xls", "xlsx", "doc", "docx", "ppt", "pptx"]
                    : ["jpeg", "jpg", "png", "svg", "webp", "gif"];

                  const fileExtension = file.name
                    .split(".")
                    .pop()
                    .toLowerCase();

                  if (!validExtensions.includes(fileExtension)) {
                    setErrorMessage(
                      `Invalid file type. Allowed types are: ${validExtensions.join(
                        ", "
                      )}`
                    );
                    return;
                  }

                  setErrorMessage(""); // Clear errors
                  previewFile(file);
                  setFileType(file.type);
                  onChange(file);
                  setValue(name, file);
                }}
                accept={
                  pdf
                    ? "application/pdf, application/vnd.ms-excel, application/msword, application/vnd.ms-powerpoint"
                    : "image/*"
                }
                className="hidden"
              />
              {previewSource || fileType ? (
                <div className="flex w-full flex-col items-center p-6">
                  {previewSource ? (
                    <img
                      src={previewSource}
                      alt="Preview"
                      className="h-full w-full rounded-md object-cover"
                    />
                  ) : (
                    renderFileIcon()
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      setPreviewSource("");
                      setFileType("");
                      setErrorMessage("");
                      onChange(null);
                      setValue(name, null);
                    }}
                    className="mt-3 text-richblack-400 underline"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="flex w-full flex-col items-center p-6">
                  <div className="grid aspect-square w-14 place-items-center rounded-full bg-pure-greys-800">
                    <FiUploadCloud className="text-2xl text-custom-blue" />
                  </div>
                  <p className="mt-2 max-w-[200px] text-center text-sm text-richblack-200">
                    Drag and drop a file, or click to{" "}
                    <span className="font-semibold text-yellow-50">Browse</span>
                  </p>
                </div>
              )}
            </>
          )}
        />
      </div>

      {errorMessage && (
        <span className="ml-2 text-xs tracking-wide text-red-500">
          {errorMessage}
        </span>
      )}

      {error && (
        <span className="ml-2 text-xs tracking-wide text-red-500">
          {label} is required
        </span>
      )}
    </div>
  );
};

export default CustomFileUpload;
