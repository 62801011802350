import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CustomButton, CustomInput, CustomSelect } from "../common";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDepartment } from "../../redux/slice/departmentSlice";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import { priorityOptions, Roles, statusOptions } from "../../constant/Constant";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { projectEndPoints } from "../../networking/Endpoints";
import { CustomAlertContext } from "../../context";

const baseUrl = process.env.REACT_APP_BASE_URL;

const AddProjectValidation = yup.object().shape({
  project_name: yup.string().required("Project Name is required").trim(),
  project_manager: yup.string().when("$userRole", {
    is: (userRole) => {
      return userRole === Roles?.admin;
    },
    then: (schema) => schema.required("Project Manager is required"),
  }),
  project_start_date: yup.string().required("Start Date is required"),
  department: yup.string().required("Department is required"),
  members: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .min(1, "You must select at least one member")
    .required("Add Members"),
});

const ProjectForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const { setToastNotification } = useContext(CustomAlertContext);
  const { employeeData } = useSelector((state) => state.employee);
  const { departmentData } = useSelector((state) => state.department);
  const [isPrivateNotes, setIsPrivateNotes] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [employees, setEmployees] = useState([]);
  const location = useLocation();
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  const project = location?.state;

  useEffect(() => {
    if (project) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [project]);
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(AddProjectValidation),
    defaultValues: {
      project_name: project?.project_name ?? "",
      project_manager: project?.project_manager?._id ?? undefined,
      team_lead: project?.team_lead?.map((item) => item?.value) ?? [],
      project_start_date: project?.project_start_date
        ? project?.project_start_date
        : formattedDate,
      department: project?.department?._id ?? "",
      members: project?.members?.map((item) => item?.value) ?? [],
      project_deadline: project?.project_deadline
        ? project?.project_deadline
        : undefined,
      projectStatus: project?.projectStatus
        ? project?.projectStatus
        : undefined,
      priority: project?.priority ? project?.priority : undefined,
      project_description: project?.project_description
        ? project?.project_description
        : undefined,
    },
    context: { userRole: user?.roleId },
  });

  const fetchDepartments = async () => {
    try {
      await dispatch(fetchDepartment({ token }));
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  const fetchEmployees = async () => {
    try {
      await dispatch(fetchAllEmployees({ token }));
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  useEffect(() => {
    if (
      !departmentData ||
      (departmentData?.page_number && departmentData?.page_size)
    ) {
      fetchDepartments();
    }
    if (
      !employeeData ||
      (employeeData?.page_number && employeeData?.page_size)
    ) {
      fetchEmployees();
    }
  }, []);

  const departmentOptions = departmentData?.departments
    ?.filter((item) => item?.isActive === true)
    ?.map((item) => ({
      name: item?.department_name,
      value: item?._id,
    }));

  const selectedProjectStartDate = watch("project_start_date");
  const selectedProjectDeadline = watch("project_deadline");
  const selectedProjectManager = watch("project_manager");
  const selectedTeamLead = watch("team_lead");
  useEffect(() => {
    if (selectedProjectManager || user?.roleId === Roles?.project_manager) {
      setError("members", {
        type: "manual",
        message: "",
      });
    }
  }, [selectedProjectManager]);

  const filteredEmployees = employeeData?.employees?.filter((item) => {
    return (
      item?.roleId !== Roles?.admin &&
      item?._id !== user?._id &&
      item?.isActive === true
    );
  });

  const employeeOptions = filteredEmployees
    ?.filter(
      (item) =>
        item?._id !== selectedProjectManager &&
        item?._id !== selectedTeamLead?.[0]
    )
    .map((item) => ({
      name: item?.user_name,
      value: item?._id,
      designation: item?.designation?.designation,
      avatar: item?.avatar
        ? `${baseUrl}/${item?.avatar}`
        : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
    }));
  const projectManagers = employeeData?.employees?.filter(
    (item) =>
      (item?.roleId === Roles?.admin ||
        item?.roleId === Roles?.project_manager) &&
      item?.isActive === true
  );
  const teamLeadData = employeeData?.employees?.filter(
    (item) =>
      (item?.roleId === Roles?.admin ||
        item?.roleId === Roles?.team_lead ||
        item?.roleId === Roles?.project_manager) &&
      item?.isActive === true
  );

  const projectManagerOptions = projectManagers?.map((item) => ({
    name: item?.user_name,
    value: item?._id,
    designation: item?.designation?.designation,
    avatar: item?.avatar
      ? `${baseUrl}/${item?.avatar}`
      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
  }));
  const teamLeadOptions = teamLeadData?.map((item) => ({
    name: item?.user_name,
    value: item?._id,
    designation: item?.designation?.designation,
    // role: item?.roleId,
    avatar: item?.avatar
      ? `${baseUrl}/${item?.avatar}`
      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
  }));

  const CheckMembers = (projectMembers, dataMembers) => {
    if (projectMembers?.length !== dataMembers?.length) {
      return false;
    }
    return projectMembers.every((item) => dataMembers.includes(item?.value));
  };

  const onSubmitHandler = async (data) => {
    let payload = isEditMode
      ? {
          project_name:
            project?.project_name !== data?.project_name
              ? data?.project_name
              : undefined,
          project_manager:
            project?.project_manager?._id !== data?.project_manager
              ? data?.project_manager
              : undefined,
          team_lead:
            project?.team_lead?._id !== data?.team_lead
              ? data?.team_lead
              : undefined,
          project_start_date:
            project?.project_start_date !== data?.project_start_date
              ? data?.project_start_date
              : undefined,
          department:
            project?.department?._id !== data?.department
              ? data?.department
              : undefined,
          members: !CheckMembers(project?.members, data?.members)
            ? data?.members
            : undefined,
          project_deadline:
            project?.project_deadline !== data?.project_deadline
              ? data?.project_deadline
              : undefined,
          projectStatus:
            project?.projectStatus !== data?.projectStatus
              ? data?.projectStatus
              : undefined,
          priority:
            project?.priority !== data?.priority ? data?.priority : undefined,
          project_description:
            project?.project_description !== data?.project_description
              ? data?.project_description
              : undefined,
        }
      : {
          ...data,
          project_notes: data?.project_notes
            ? { title: data?.project_notes, isPrivateNotes: isPrivateNotes }
            : undefined,
        };

    dispatch(setLoading(true));
    try {
      const apiMethod = isEditMode ? "PUT" : "POST";
      const apiUrl = isEditMode
        ? `${projectEndPoints?.PROJECT_API}/${project?._id}`
        : projectEndPoints?.PROJECT_API;
      const project_response = await apiConnector(apiMethod, apiUrl, payload, {
        Authorization: `Bearer ${token}`,
      });
      toast.success(
        isEditMode
          ? "Project updated successfully"
          : "Project created Successfully"
      );
      navigate(-1);
      reset();
    } catch (error) {
      setToastNotification(error?.message, "error");
      reset();
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="w-full border bg-custom-white rounded-md">
      <form
        className="w-full p-6 "
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="text-xl font-semibold text-custom-black">
          {isEditMode ? "Edit Project" : "Add Project"}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
          <div className="my-2 sm:my-4">
            <CustomInput
              name="project_name"
              label="Project Name"
              inputType="text"
              placeholder=""
              control={control}
              required={true}
              error={errors?.project_name?.message}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="project_start_date"
              label="Start Date"
              inputType="date"
              placeholder=""
              control={control}
              required={true}
              error={errors?.project_start_date?.message}
              max={selectedProjectDeadline ?? ""}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="project_deadline"
              label="Project Deadline"
              inputType="date"
              placeholder=""
              control={control}
              min={selectedProjectStartDate ?? ""}
            />
          </div>
          {user?.roleId === Roles?.admin && (
            <div className="my-2 sm:my-4 ">
              <CustomSelect
                name="project_manager"
                label="Project Manager"
                inputType="text"
                placeholder="Select..."
                control={control}
                required={true}
                options={projectManagerOptions}
                error={errors?.project_manager?.message}
              />
            </div>
          )}
          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="team_lead"
              label="Team Lead"
              inputType="text"
              placeholder="Select..."
              control={control}
              required={true}
              multiple={true}
              options={teamLeadOptions}
              error={errors?.team_lead?.message}
            />
          </div>
          <div className="my-2 sm:my-4">
            <CustomSelect
              name="department"
              label="Department"
              inputType="text"
              placeholder="Select..."
              control={control}
              required={true}
              options={departmentOptions}
              error={errors?.department?.message}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="projectStatus"
              label="Status"
              inputType="text"
              placeholder="Select..."
              control={control}
              options={statusOptions}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="priority"
              label="Priority"
              inputType="text"
              placeholder="Select..."
              control={control}
              options={priorityOptions}
            />
          </div>
          <div
            onClick={() => {
              if (user?.roleId === Roles?.admin && !selectedProjectManager) {
                setError("members", {
                  type: "manual",
                  message: "Note:- Please Select a Project Manager First!",
                });
              }
            }}
            className="my-2 sm:my-4"
          >
            <CustomSelect
              name="members"
              label="Add Members"
              inputType="text"
              placeholder="Select..."
              control={control}
              required={true}
              multiple="true"
              options={employeeOptions}
              error={errors?.members?.message}
              isEditable={
                user?.roleId === Roles?.admin && !selectedProjectManager
              }
            />
          </div>
        </div>
        <div className="my-2 sm:my-4 grid-cols-3">
          <CustomInput
            name="project_description"
            label="Project Summary"
            inputType="text-area"
            placeholder=""
            control={control}
          />
        </div>
        {!isEditMode && (
          <div className="relative my-2 sm:my-4">
            <label className="absolute right-1 -top-1 flex justify-end items-center gap-2 cursor-pointer">
              <span className="ms-3 text-sm font-medium text-gray-900">
                Private Notes
              </span>
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                onChange={() => setIsPrivateNotes(!isPrivateNotes)}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-custom-blue"></div>
            </label>

            <CustomInput
              name="project_notes"
              label="Project Notes"
              inputType="text-area"
              placeholder=""
              control={control}
            />
          </div>
        )}
        <div className="flex justify-end items-center my-10 gap-4">
          <CustomButton
            title={"Cancel"}
            buttonType="button"
            onClick={() => navigate("/project")}
            classname={`border font-semibold text-custom-black px-5 py-1 rounded-md 
            `}
          />
          <CustomButton
            title={
              loading ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.591C100 78.205 77.614 100.591 50 100.591S0 78.205 0 50.591 22.386 0.591 50 0.591 100 22.977 100 50.591ZM9.081 50.591C9.081 73.189 27.401 91.509 50 91.509S90.919 73.189 90.919 50.591 72.599 9.672 50 9.672 9.081 27.992 9.081 50.591Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.968 39.041c2.426-.637 3.896-3.129 3.041-5.486-1.715-4.731-4.137-9.185-7.191-13.206C85.845 15.12 80.883 10.724 75.212 7.413 69.542 4.102 63.275 1.94 56.77 1.051c-5.003-.684-10.072-.604-15.035.228-2.473.414-3.921 2.919-3.284 5.344.637 2.426 3.119 3.849 5.6 3.484 3.801-.559 7.669-.581 11.49-.103 5.324.727 10.453 2.497 15.093 5.206 4.641 2.71 8.702 6.307 11.952 10.586 2.333 3.071 4.215 6.45 5.596 10.035.902 2.34 3.361 3.802 5.787 3.165Z"
                    fill="currentFill"
                  />
                </svg>
              ) : isEditMode ? (
                "Save"
              ) : (
                "Add Project"
              )
            }
            buttonType="submit"
            disabled={loading}
            classname={`bg-green-500 font-semibold text-custom-white px-5 py-1 rounded-md ${
              loading ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          />
        </div>
      </form>
    </div>
  );
};

export default ProjectForm;
