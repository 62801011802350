import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { apiConnector } from "../../networking/ApiConnector";
import { employeeEndPoints } from "../../networking/Endpoints";
import { setLoading } from "../../redux/slice/loadingSlice";
import { toast } from "react-toastify";
import ProjectCard from "../project/ProjectCard";
import Skeleton from "react-loading-skeleton";
import { Heading } from "../project";
import { Roles } from "../../constant/Constant";
import { CustomAlertContext } from "../../context";
import { CustomButton } from "../common";
import { MdOutlineEdit } from "react-icons/md";
import { GrView } from "react-icons/gr";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const EmployeeView = () => {
  const { token, user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const employee_id = location?.state?.id;
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { setToastNotification } = useContext(CustomAlertContext);

  const fetchEmployeeDetails = async () => {
    dispatch(setLoading(true));
    try {
      const employee_response = await apiConnector(
        "GET",
        `${employeeEndPoints?.EMPLOYEE_API}/${employee_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setEmployeeDetails(employee_response?.data?.data?.[0]);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (employee_id) {
      fetchEmployeeDetails();
    }
  }, [employee_id]);
  return loading ? (
    <div className="w-full bg-custom-white p-6 rounded-lg">
      <section className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 border-b border-[#DEE9F0] py-4">
        <Skeleton height={24} width={150} />
        <Skeleton height={24} width={200} />
        <Skeleton height={24} width={250} />
        <Skeleton height={24} width={180} />
        <Skeleton height={24} width={200} />
        <Skeleton height={24} width={180} />
      </section>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {[1, 2, 3, 4, 5, 6]?.map((index) => (
          <div
            key={index}
            className="w-full border border-[#E8F0F6] bg-custom-white p-4 rounded-lg shadow-[2px_3px_10px_6px_#257CBB0F] space-y-3 cursor-pointer flex flex-col"
          >
            <div className="flex justify-between items-center space-x-2">
              <div className="w-40">
                <Skeleton width={"70"} />
              </div>
              <div className="w-[15px] h-[15px]">
                <Skeleton circle width={"100%"} height="100%" />
              </div>
            </div>
            <div>
              <Skeleton count={3} />
            </div>
            <div className="flex space-x-2">
              <div className="w-[25px] h-[25px]">
                <Skeleton circle width={"100%"} height="100%" />
              </div>
              <div className="w-[25px] h-[25px]">
                <Skeleton circle width={"100%"} height="100%" />
              </div>
              <div className="w-[25px] h-[25px]">
                <Skeleton circle width={"100%"} height="100%" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="w-full bg-custom-white rounded-lg p-6">
      <div className="flex justify-end items-center">
        {(user?.roleId === Roles?.admin || user?.roleId === Roles?.hr) &&
          employeeDetails?.roleId !== Roles?.admin && (
            <div
              onClick={() => {
                navigate("/employees/create", {
                  state: {
                    employee: {
                      ...employeeDetails,
                      office_location: employeeDetails?.office_location?._id,
                    },
                  },
                });
              }}
              className="p-1 border border-custom-blue text-custom-blue hover:text-custom-white hover:bg-custom-blue  hover:border-custom-white rounded-md cursor-pointer"
            >
              <MdOutlineEdit size={16} />
            </div>
          )}
      </div>
      <div className="w-full flex md:flex-row flex-col justify-between items-center border-b gap-5">
        <img
          src={
            employeeDetails?.avatar
              ? `${BASE_URL}/${employeeDetails?.avatar}`
              : `https://api.dicebear.com/5.x/initials/svg?seed=${employeeDetails?.user_name}`
          }
          alt={employeeDetails?.user_name?.[0] ?? "Profile Image"}
          loading="lazy"
          className="w-16 md:w-24  h-16 md:h-24 rounded-full"
        />
        <section className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6 border-[#DEE9F0] py-4">
          <Heading label="Employee ID" value={employeeDetails?.emp_id} />
          <Heading label="Name" value={employeeDetails?.user_name} />
          <Heading label="Email" value={employeeDetails?.email} />
          <Heading
            label="Mobile Number"
            value={employeeDetails?.mobile_number}
          />
          <Heading
            label="Department"
            value={
              employeeDetails?.roleId === Roles?.admin
                ? "Admin"
                : employeeDetails?.department?.department_name ?? "-"
            }
          />
          <Heading
            label="Designation"
            value={
              employeeDetails?.roleId === Roles?.admin
                ? "Admin"
                : employeeDetails?.designation?.designation ?? "-"
            }
          />
          <Heading
            label="Work Mode"
            value={employeeDetails?.work_mode ?? "--"}
          />
          <Heading
            label="Office Location"
            value={employeeDetails?.office_location?.city ?? "--"}
          />
        </section>
      </div>
      <section className="w-full mt-5 space-y-5 ">
        <h1 className="font-semibold">All Projects</h1>
        <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {employeeDetails?.projects &&
          employeeDetails?.projects?.length > 0 ? (
            employeeDetails?.projects?.map((project, index) => (
              <ProjectCard
                key={index}
                _id={project?._id}
                project_name={project?.project_name}
                project_manager={project?.project_manager}
                department={project?.department}
                project_id={project?.project_id}
                project_description={project?.project_description}
                start_date={project?.project_start_date}
                end_date={project?.project_deadline}
                assignees={project?.members}
                status={project?.projectStatus}
                onClick={() =>
                  navigate("/project/view", { state: { id: project?._id } })
                }
              />
            ))
          ) : (
            <div className="font-semibold text-sm">No Projects Available</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default EmployeeView;
