import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { projectEndPoints } from "../../networking/Endpoints";
import { ApiError } from "../../helper/ApiError";
import { toast } from "react-toastify";

import { getAllTeamTask } from "../../redux/slice/taskSlice";
import ProjectDetails from "./ProjectDetails";
import ProjectMembers from "./ProjectMembers";
import { CustomButton } from "../common";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import ProjectOverview from "./ProjectOverview";
import { setActiveTabInProject } from "../../redux/slice/projectSlice";
import ProjectMilestone from "./ProjectMilestone";
import ProjectHistory from "./ProjectHistory";
import { Roles } from "../../constant/Constant";
import ProjectTeamTask from "./ProjectTeamTask";
import { CustomAlertContext } from "../../context";
import ProjectDocumentation from "./ProjectDocumentation";
import ProjectSheet from "./ProjectSheet";

const ProjectView = () => {
  const { token, user } = useSelector((state) => state.auth);
  const { activeTabInProject } = useSelector((state) => state.project);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [projctDetailName, setProjctDetailName] = useState("Add Project Sheet");
  const [project, setProject] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location?.state?.id;

  const handleTabClick = (tab) => {
    dispatch(setActiveTabInProject(tab));
  };
  const fetchProjectDetails = async () => {
    dispatch(setLoading(true));
    try {
      const project_response = await apiConnector(
        "GET",
        `${projectEndPoints?.PROJECT_API}/${projectId}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setProject(project_response?.data?.projectDetail);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchProjectDetails();
    }
  }, [projectId]);

  useEffect(() => {
    if (user?.roleId === Roles?.employee)
      dispatch(setActiveTabInProject("project_details"));
  }, [user]);

  return (
    <div className="w-full h-full bg-custom-white space-y-2 ">
      <section className="relative w-full bg-[#E6F0F6] flex justify-start gap-2 md:gap-10 overflow-x-auto font-semibold">
        <div className="px-4 py-2">
          <CustomButton
            title={<MdOutlineKeyboardBackspace size={24} />}
            buttonType={"button"}
            onClick={() => navigate(-1)}
            classname={`w-8 h-8 rounded-full flex justify-center items-center bg-custom-blue text-custom-white`}
          />
        </div>
        {/* {user?.roleId !== Roles?.employee && ( */}
        <h2
          onClick={() => handleTabClick("overview")}
          className={`cursor-pointer p-4 font-semibold text-sm ${
            activeTabInProject === "overview"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Overview
        </h2>
        {/* )} */}
        <h2
          onClick={() => handleTabClick("project_details")}
          className={`cursor-pointer p-4 font-semibold text-sm ${
            activeTabInProject === "project_details"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Project Details
        </h2>
        {user?.roleId !== Roles?.employee && (
          <h2
            onClick={() => {
              setProjctDetailName("Add Project Sheet");
              handleTabClick("project_sheet");
            }}
            className={`cursor-pointer p-4 font-semibold text-sm ${
              activeTabInProject === "project_sheet"
                ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
                : ""
            }`}
          >
            Project Sheet
          </h2>
        )}
        <h2
          onClick={() => handleTabClick("team_task")}
          className={`cursor-pointer p-4 font-semibold text-sm ${
            activeTabInProject === "team_task"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Team Task
        </h2>
        <h2
          onClick={() => handleTabClick("members")}
          className={`cursor-pointer p-4 font-semibold text-sm ${
            activeTabInProject === "members"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Members
        </h2>
        {user?.roleId !== Roles?.employee && (
          <h2
            onClick={() => handleTabClick("milestone")}
            className={`cursor-pointer p-4 font-semibold text-sm ${
              activeTabInProject === "milestone"
                ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
                : ""
            }`}
          >
            Project Milestone
          </h2>
        )}
        <h2
          onClick={() => handleTabClick("project_documentation")}
          className={`cursor-pointer p-4 font-semibold text-sm ${
            activeTabInProject === "project_documentation"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Project Docs
        </h2>
        {user?.roleId !== Roles?.employee && (
          <h2
            onClick={() => handleTabClick("project_logs")}
            className={`cursor-pointer p-4 font-semibold text-sm ${
              activeTabInProject === "project_logs"
                ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
                : ""
            }`}
          >
            Project History
          </h2>
        )}
      </section>
      <div className="w-full h-full">
        {activeTabInProject === "overview" && (
          // user?.roleId !== Roles?.employee &&
          <ProjectOverview project_id={projectId} />
        )}
        {activeTabInProject === "project_details" && (
          <ProjectDetails
            setProjctDetailName={setProjctDetailName}
            project={project}
            fetchProjectDetails={fetchProjectDetails}
          />
        )}
        {activeTabInProject === "team_task" && (
          <ProjectTeamTask project={project} />
        )}
        {activeTabInProject === "members" && (
          <ProjectMembers project={project} />
        )}

        {activeTabInProject === "milestone" &&
          user?.roleId !== Roles?.employee && (
            <ProjectMilestone
              projectId={projectId ?? project?._id}
              projectStartDate={project?.project_start_date}
              projectDeadline={project?.project_deadline}
            />
          )}
        {activeTabInProject === "project_logs" &&
          user?.roleId !== Roles?.employee && (
            <ProjectHistory projectID={projectId ?? project?._id} />
          )}
        {activeTabInProject === "project_documentation" ? (
          <ProjectDocumentation
            project={project}
            members={project?.members}
            fetchProjectDetails={fetchProjectDetails}
          />
        ) : (
          ""
        )}
        {activeTabInProject === "project_sheet" && (
          <ProjectSheet project={project} projctDetailName={projctDetailName} />
        )}
      </div>
    </div>
  );
};

export default ProjectView;
