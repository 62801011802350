import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Roles } from "../../constant/Constant";
import SuperAdminDashboard from "./SuperAdminDashboard";
import AdminDashboard from "./AdminDashboard";
import ProjectManagerDashboard from "./ProjectManagerDashboard";
import TeamLeadDashboard from "./TeamLeadDashboard";
import HrDashboard from "./HrDashboard";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { dashboardEndPoints } from "../../networking/Endpoints";
import { CustomAlertContext } from "../../context";
import EmployeeDashboard from "./EmployeeDashboard";

const Dashboard = () => {
  const { token, user } = useSelector((state) => state.auth);

  return (
    <div className="w-full bg-custom-white border border-slate-200 p-3 rounded-md">
      {user?.roleId === Roles?.super_admin && <SuperAdminDashboard />}
      {user?.roleId === Roles?.admin && <AdminDashboard />}
      {/* {user?.roleId === Roles?.project_manager && <ProjectManagerDashboard />}
      {user?.roleId === Roles?.team_lead && <TeamLeadDashboard />} */}
      {user?.roleId === Roles?.hr && <HrDashboard />}
      {(user?.roleId === Roles?.employee ||
        user?.roleId == Roles?.project_manager ||
        user?.roleId == Roles?.team_lead) && <EmployeeDashboard />}
    </div>
  );
};

export default Dashboard;
