import React from "react";

const TableHeader = ({ TableHeads }) => {
  return (
    <tr className="text-sm text-left border rounded-lg text-slate-600 bg-[#E3F0FA] font-medium">
      {TableHeads?.map((item, index) => {
        return (
          <th key={index} className="p-2 whitespace-nowrap">
            {item}
          </th>
        );
      })}
    </tr>
  );
};

export default TableHeader;
